import React, { useState, useEffect } from 'react';
import './index.css';
import data from './games/1.json';

const App = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [score, setScore] = useState(0);
  const [answered, setAnswered] = useState(false);
  const [dots, setDots] = useState([0, 0, 0, 0, 0]);
  const [showProbabilities, setShowProbabilities] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleChoice = (choice) => {
    if (!answered) setSelectedChoice(choice);
  };

  const handleSubmit = () => {
    if (selectedChoice !== null && !answered) {
      const isChoiceCorrect = data[currentQuestion][`Probability ${selectedChoice}`] > data[currentQuestion][`Probability ${selectedChoice === 'A' ? 'B' : 'A'}`];
      setIsCorrect(isChoiceCorrect);
      setAnswered(true);
      setShowProbabilities(true);
      setDots(dots.map((dot, index) => (index === currentQuestion ? (isChoiceCorrect ? 1 : -1) : dot)));
      if (isChoiceCorrect) setScore(score + 1);
      setTimeout(() => {
        setAnswered(false);
        setShowProbabilities(false);
        setSelectedChoice(null);
        setCurrentQuestion(currentQuestion + 1);
        setIsCorrect(null);
      }, 10000); // 10 seconds delay for showing probabilities and transition
    }
  };

  if (currentQuestion >= data.length) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <h1 className="text-3xl font-bold">Your Score: {score} / 5</h1>
        <div className="flex justify-center mt-4">
          {dots.map((dot, index) => (
            <div key={index} className={`w-4 h-4 mx-1 rounded-full ${dot === 1 ? 'bg-green-500' : dot === -1 ? 'bg-red-500' : 'bg-gray-400'}`}></div>
          ))}
        </div>
        <button className="mt-6 px-4 py-2 bg-blue-500 text-white rounded">Share your score</button>
        <div className="mt-2 text-gray-500">Next Game Begins in X:XX:XX</div>
      </div>
    );
  }

  const getButtonClass = (choice) => {
    if (!answered) return selectedChoice === choice ? 'bg-blue-500 text-white' : 'bg-gray-200';
    if (answered && selectedChoice === choice) return isCorrect ? 'bg-green-500 text-white' : 'bg-red-500 text-white';
    return 'bg-gray-200';
  };

  return (
    <div className={`min-h-screen flex flex-col items-center ${answered ? (isCorrect ? 'bg-green-200' : 'bg-red-200') : 'bg-gray-100'}`}>
      <header className="w-full bg-blue-500 text-white py-4 text-center text-2xl font-bold">
        The Predictor Game
      </header>
      <div className="mt-4 flex justify-center">
        {dots.map((dot, index) => (
          <div key={index} className={`w-4 h-4 mx-1 rounded-full ${dot === 1 ? 'bg-green-500' : dot === -1 ? 'bg-red-500' : 'bg-gray-400'}`}></div>
        ))}
      </div>
      <div className="mt-8 p-4 border rounded-lg bg-ivory w-3/4 text-center">
        <div>Which is more likely?</div>
        <br></br><div>(1According to prediction markets)</div>
      </div>
      <div className="mt-4 w-3/4">
        <button
          onClick={() => handleChoice('A')}
          className={`block w-full p-4 border rounded-lg mt-4 ${getButtonClass('A')}`}
        >
          
          {data[currentQuestion]['Event A']} {showProbabilities && `(${data[currentQuestion]['Probability A']}%)`}
        </button>
        <button
          onClick={() => handleChoice('B')}
          className={`block w-full p-4 border rounded-lg mt-4 ${getButtonClass('B')}`}
        >
          
          {data[currentQuestion]['Event B']} {showProbabilities && `(${data[currentQuestion]['Probability B']}%)`}
        </button>
      </div>
      <button
        onClick={handleSubmit}
        className={`mt-8 px-6 py-2 rounded-lg ${answered ? (isCorrect ? 'bg-green-500' : 'bg-red-500') : 'bg-blue-500 text-white'}`}
      >
        {answered ? 'Next' : 'Submit'}
      </button>
      <footer className="w-full bg-blue-500 text-white py-4 text-center text-sm fixed bottom-0">
        Created by Joe Lemay
      </footer>
    </div>
  );
};

export default App;
